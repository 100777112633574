import React from 'react'
import {graphql, navigate} from 'gatsby'
import {ModalRoutingContext} from 'gatsby-plugin-modal-routing'
import Header from "../components/header";
import Layout from "../components/layout";
import Helmet from "react-helmet";

export default class JobsPage extends React.Component {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            return navigate('/team')
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }


    render() {
        return (
            <ModalRoutingContext>
                {({modal, closeTo}) => (
                    <div>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>Team | Paixon</title>
                            <meta name="description"
                                  content="Werde ein Teil vom Paixon-Team. Bewerbe dich jetzt auf eine unserer offenen Stellen. Wir freuen uns auch über Blindbewerbungen."/>
                        </Helmet>
                        {modal ? (
                            <div>
                                <a href={'/team'} className="exitButton" />
                                <div className="jobsPageOffer">
                                    <h2>Jobs</h2>
                                    <p> Aktuell sind wir in voller Besetzung. Wenn du denkst, dass du bei uns etwas
                                        bewegen kannst, freuen wir uns dennoch über deine Spontanbewerbung. </p>

                                    <div className="offerList"></div>
                                </div>
                            </div>
                        ) : (
                            <div className="normalPage">
                                <a href={'/team'} className="exitButton"/>
                                <div className="jobsPageOffer">
                                    <h2>Jobs</h2>
                                    <p> Aktuell sind wir in voller Besetzung. Wenn du denkst, dass du bei uns etwas
                                        bewegen kannst, freuen wir uns dennoch über deine Spontanbewerbung. </p>

                                    <div className="offerList"></div>
                                </div>
                            </div>

                        )}
                    </div>

                )}
            </ModalRoutingContext>
        )
    }
};


export const pageQuery = graphql` 
query JobsPage {
    allStrapiTeamPages {
        edges {
            node {
                content
                slogan
                Photo {
                    publicURL
                }
            }
        }
    }
}`
